<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Final Learning Reflection
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h1 class="heading-title">Personal Reflection</h1>
                      <p>
                        Reflecting on my recent experience in the grant writing
                        course, I am struck by the comprehensive skillset I have
                        developed and the profound influence this knowledge will
                        have on my future research endeavors. The course's
                        multifaceted approach has enhanced my understanding of
                        the grant writing process, research design, and ethical
                        considerations, while also fostering my professional and
                        personal growth.
                      </p>
                      <p>
                        The course began by introducing the intricacies of grant
                        writing, a process that, at first glance, seemed
                        daunting. However, as we delved deeper, I began to
                        appreciate the art of crafting a compelling narrative
                        around a research project. The two proposals I worked on
                        - "Investigating the Effectiveness of Gamification in
                        Higher Education" and "Exploring Gamification Strategies
                        for Enhanced Learning in Higher Education" - were my
                        opportunities to put theory into practice.
                      </p>
                      <p>
                        These proposals allowed me to explore the fascinating
                        intersection of gamification and higher education, a
                        topic of personal interest. I had to distill complex
                        concepts into understandable language and create a
                        compelling argument that would resonate with the Spencer
                        Foundation, my hypothetical grant provider. The
                        challenge of conveying the significance of these
                        projects, their feasibility, and the potential impact on
                        higher education became an engaging puzzle that honed my
                        problem-solving and communication skills.
                      </p>
                      <p>
                        In the first proposal, "Investigating the Effectiveness
                        of Gamification in Higher Education," I focused on
                        filling the empirical gap in gamification's impact on
                        higher education. I learned the importance of clearly
                        articulating the research questions and outlining a
                        detailed methodology for data collection and analysis.
                        The process of developing the project's budget made me
                        realize the practical implications of conducting
                        research and the necessity of careful financial
                        planning.
                      </p>
                      <p>
                        The second proposal, "Exploring Gamification Strategies
                        for Enhanced Learning in Higher Education," allowed me
                        to delve further into the practical application of
                        gamification in learning environments. Here, the
                        challenge was to customize gamification strategies for
                        various disciplines and course structures, keeping in
                        mind the diverse needs of students. It required me to
                        think creatively and consider different scenarios in
                        which gamification could be applied effectively.
                      </p>
                      <p>
                        Simultaneously, these proposals made me realize the
                        importance of a research project's broader impact. It is
                        not enough for research to exist in an academic vacuum;
                        it should contribute to societal betterment. Hence, I
                        focused on outlining the potential positive effects of
                        gamification strategies on student engagement and
                        learning outcomes in higher education.
                      </p>
                      <p>
                        The course also underscored the importance of
                        collaboration and peer feedback. It provided me with an
                        opportunity to work with diverse peers, each bringing a
                        unique perspective to the table. This collaborative
                        environment enriched my learning experience, helping me
                        appreciate different viewpoints and the value of
                        constructive criticism.
                      </p>
                      <p>
                        Furthermore, the course has ignited my passion for
                        academic research. The process of exploring a topic
                        in-depth, formulating research questions, and designing
                        a study to answer those questions has been
                        intellectually stimulating. It has fostered a sense of
                        curiosity and a desire to contribute to the body of
                        knowledge in my field of interest.
                      </p>
                      <p>
                        In summary, the grant writing course has been a
                        transformative journey. It has equipped me with a robust
                        set of skills - from grant writing to research design,
                        from ethical considerations to budgeting. It has also
                        instilled in me the importance of conveying research
                        significance and potential societal impacts. Moreover,
                        the course has honed my resilience, adaptability, and
                        collaboration skills, which are crucial for any
                        researcher.
                      </p>
                      <p>
                        Reflecting on the two proposals, I can see a clear
                        progression in my understanding and application of the
                        grant writing process. From the initial stages of
                        articulating a research problem and questions in the
                        first proposal, to designing tailored gamification
                        strategies and establishing their potential impact in
                        the second proposal, each stage has been a learning
                        curve.
                      </p>
                      <p>
                        The process of creating a budget for each proposal was
                        particularly enlightening. It forced me to consider the
                        financial aspects of research implementation, a facet I
                        had previously overlooked. I had to critically evaluate
                        each cost element - personnel, equipment, travel, and
                        others - to ensure that the budget was both realistic
                        and justifiable. This experience taught me that while
                        the intellectual merit of a project is paramount,
                        practical considerations like budgeting cannot be
                        ignored.
                      </p>

                      <p>
                        The course also emphasized the importance of effective
                        communication in grant writing. It is essential to
                        articulate complex ideas in a manner that is accessible
                        to diverse audiences, including those outside of one's
                        field. This skill is not only critical for obtaining
                        funding but also for ensuring that research findings
                        reach a broader audience, thereby maximizing their
                        impact.
                      </p>
                      <p>
                        Overall, this course has been much more than a graduate
                        class. It has been a holistic learning experience that
                        has prepared me for the complexities and challenges of
                        research. It has taught me that successful grant writing
                        is a blend of clear communication, rigorous research
                        design, meticulous budget planning, ethical mindfulness,
                        and a deep understanding of the project's broader
                        impacts.
                      </p>
                      <p>
                        Looking ahead, I am excited to apply the skills and
                        insights I have gained from this course in my future
                        research endeavors. I feel equipped and motivated to
                        contribute to academic scholarship and societal
                        development in meaningful ways. Whether it is
                        formulating impactful research questions, developing a
                        rigorous methodology, crafting a compelling grant
                        proposal, or conducting ethically sound research - I am
                        ready to take on these challenges with confidence and
                        competence.
                      </p>
                      <p>
                        In conclusion, the grant writing course has been a
                        transformative learning journey. It has equipped me with
                        the tools and knowledge to navigate the world of
                        academic research effectively. I am eager to bring the
                        same rigor, curiosity, and commitment that I learned in
                        this course to all my future scholarly endeavors.
                      </p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5870-3"
                          >Back to LDTE 5870</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
